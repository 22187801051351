<template>
  <div class="useraddress">
    <headbox
      :title="titleiInt"
      bgColor="#39394D"
      isborder="true"
      isnohref="true"
    ></headbox>
        <div class="box">
              <!-- 取货地址 -->
     <div class="flex">
      <div class="titleinfo">联系人</div>
      <div>
        <el-input placeholder="请输入" v-model="postForm.name"></el-input>
      </div>
     </div>
     <div class="flex">
      <div class="titleinfo">联系电话</div>
      <div>
        <el-input
          placeholder="请输入"
          v-model="postForm.phone"
          maxlength="11"
        ></el-input>
      </div>
     </div>
     <div class="flex cityselect">
      <div class="titleinfo">所在城市</div>
      <div
        @click="showPopup"
        :class="issslect ? 'citybox drepcolor' : ' citybox'"
        style="flex:2"
      >
        {{ initCity }}
      </div>
      <div><i class="el-icon-arrow-right"></i></div>
     </div>
     <div class="flex nonePadd ">
      <div class="titleinfo">详细地址</div>
      <div style="flex:1">
        <el-input
          placeholder="例如：**街**号"
          v-model="postForm.textareabox"
          type="textarea"
          :rows="2"
          class="textareabox"
        ></el-input>
      </div>
     </div>
    <div class="flex  lastbox " v-if="defaultAddress">
      <div><el-checkbox v-model="checked"></el-checkbox></div>
      <div class="titleinfo onlyaddress">设置为默认地址</div>
      
    </div>
          <!-- 保存按钮 -->
      <div class="save" :style="{background:save?'#39394d':'#39394d'}" @click="savedata">
        {{saveName}}
      </div>
    </div>


    <div>
      <van-popup v-model="show" position="bottom">
        <van-area
          :area-list="areaList"
          :value="addrCode"
          @confirm="confirmF"
          cancel-button-text=" "
          
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
import "vant/lib/popup/style";
import "vant/lib/area/style";
import AreaList from "../pickUp/area";
import headbox from "@/components/head.vue";
import * as addaddressapi from "@/utils/api";

export default {
  data() {
    return {
      checked: false,
      postForm: {
        name: "",
        phone: "",
        textareabox: ""
      },
      issslect: false,
      show: false,
      addrCode: "110101",
      areaList: AreaList,
      initCity: "请选择省、市、区",
      // 选择地区的code
      selectCity: 0,
      titleiInt: "新增地址",
      // 是否从上门取货页面过来的
      ishavepickup: false,
      // 保存按钮是否显示
      save:false,
      // 保存按钮名称
      saveName:'保存',
      // 编辑页面设置默认地址盒子是否显示
      defaultAddress:false
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.ishavepickup = from.query.type || "";
    });
  },
  mounted() {
    const querymsg = this.$route.query;
    if (this.$route.query.type == "edit") {
      this.defaultAddress = true
      this.saveName = '编辑保存'
      this.titleiInt = "编辑地址";
      this.postForm.name = querymsg.name;
      this.postForm.phone = querymsg.phone;
      this.initCity = querymsg.preAddr;
      this.addrCode = querymsg.citycode;
      this.postForm.textareabox = querymsg.address;
    }
  },
  components: {
    headbox
  },
  watch: {
	    postForm: {
			  handler(newVal, oldVal) {
              if (newVal.name && newVal.phone && newVal.textareabox ) {
                  this.save = true
              }else{
                this.save = false
              }
			  },
        
		    	immediate: true,
		    	deep: true
		}

	},
  methods: {
    savedata() {
      if (!this.save) {
        return
      }
      if (!this.postForm.name) {
        return this.$toast.fail("联系人不能为空"), !1;
      }
      if (!/^[1][0-9]{10}$/.test(this.postForm.phone)) {
        return this.$toast.fail("手机号格式错误"), !1;
      }
      if (!this.postForm.textareabox) {
        return this.$toast.fail("详情地址不能为空"), !1;
      }
      this.save = true
      const postData = {
        // 详细地址
        address: this.postForm.textareabox,
        // 地区code
        code: this.selectCity == 0 ? this.addrCode : this.selectCity[2].code,
        //  是否默认地址
        defAddr: this.checked ? "1" : "0",
        // 地址ID
        // "id": 0,
        // 收件人名
        name: this.postForm.name,
        // 收件人手机号
        phone: this.postForm.phone
      };
      if (this.$route.query.type == "edit") {
        // 修改地址
        addaddressapi.edditAddress(this.$route.query.Id, postData).then(res => {
          console.log('res',res);
          this.$router.push({
            name: "address",
            query: { type: this.ishavepickup }
          });
        });
      } else {
        // 新增地址
        addaddressapi.addAddress(postData).then(res => {
          this.$router.push({
            name: "address",
            query: { type: this.ishavepickup }
          });
        });
      }
    },
    confirmF(picker, value, index) {
      this.show = false;
      this.issslect = true;
      this.initCity =
        picker[0].name + " " + picker[1].name + " " + picker[2].name;
      this.selectCity = picker;
    },
    showPopup() {
      this.show = true;
    }
  }
};
</script>
<style lang="scss" scoped="true">
::v-deep .van-picker-column__wrapper {
  font-size: 30px !important;
}
::v-deep .van-picker__confirm {
  font-size: 32px;
}
::v-deep .van-picker__toolbar {
  height: 80px;
}
.cityselect {
  align-items: center;
}
.textareabox {
  ::v-deep .el-textarea__inner {
    border: 0;
    // color:#000000;
    font-size: 32px;
    color: #606266;
    padding: 0;
    height: 100px;
    resize: none;
    width: 100%;
    margin-left: 15px;
  }
}
.useraddress {
  height: 100vh;
  background: #f5f5f5;
  .citybox {
    margin-left: 15px;
    color: #c0c4d6;
  }
  .titleinfo {
    width: 150px;
    margin-right: 25px;
    color: #242424;
  }
  .onlyaddress {
    width: 200px;    
    margin-left: 10px;
    font-size: 24px;
    line-height: 52px;

  }
  .flex {
    font-size: 32px;
    background: #fff;
    padding: 30px 25px;
    border-bottom: 1px solid #efefef;
    color: #242424;
  }
  .initaddress {
    margin-top: 30px;
  }
  .lastbox {
    border-bottom: 0;
  }
}
.detailsbox {
  color: #cccccc;
  font-size: 28px;
}
.titlebox {
  font-size: 36px;
  margin-bottom: 19px;
}
.checkbox {
  margin: 90px auto 40px;
  background: #20c88b;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  position: relative;

  i {
    position: absolute;
    left: 50%;
    top: 20%;
    font-size: 86px;
    color: #fff;
    transform: translate(-50%);
  }
}
::v-deep .el-input__inner {
  border: 0;
  font-size: 32px;
}
::v-deep{ 
  .el-checkbox__inner{
      border-radius: 50% !important;
    }
  .el-checkbox__input.is-checked .el-checkbox__inner{
      background: #ff2d2e !important;
    }
    .el-checkbox__inner:hover{
      border-color:#ff2d2e !important;
    }
}
.cerbox {
  padding: 0 40px;
  p {
    font-size: 28px;
  }
  cite {
    display: block;
    margin-bottom: 30px;
  }
  .usercenter {
    input {
      border: 0;
    }
  }
  .userbox {
    font-size: 32px;
    border-bottom: 1px solid #cccccc;
    i {
      font-style: normal;
    }
    b {
      margin-right: 40px;
      display: inline-block;
      padding: 30px 0;
      font-weight: normal;
      width: 150px;
    }
  }
  .imgboxlist {
    margin-bottom: 50px;
  }
  .idCard {
    width: 90%;
  }
  .photobox {
    width: 20%;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%);
  }
  .tip {
    color: #b8b8b8;
    font-size: 24px;
    margin: 30px 0 50px 0;
  }
}
h1 {
  font-size: 40px;
  margin-bottom: 40px;
  font-weight: normal;
}
.btn_yellow {
  margin-top: 80px;
  background: #ffb606;
  color: #fff;
  border-radius: 44px;
  border: 0;
  width: 650px;
  height: 88px;
  font-size: 36px;
}
.drepcolor {
  color: #606266 !important;
}
.nonePadd{
  padding-bottom: 0 !important;
}
.box{
  padding: 30px;
  border-radius: 8px 8px 8px 8px;
}
.save{
  width: 100%;
  height: 84px;
  margin-top: 84px;
  background-color: #39394d;
  border-radius: 50px;
  text-align: center;
  line-height: 84px;
  font-size: 32px;
  color: #f6d0ab;
}
</style>
